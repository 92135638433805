import Router from 'config/routes';
import "./App.css";

const App = () => {

  return (
    <Router />
  )
}

export default App
