import ky from "ky";

import useSWR from "swr";

import { BASE_URL } from "config/urls";

export const get = <R>(url: string) => ky.get(url).json<R>();

export const post = <R>(url: string, data: unknown) =>
  ky.post(url, { json: data }).json<R>();

export const useFetch = <D>(url: string | undefined) => {
  const { data, ...rest } = useSWR<{ data: D; total: number }>(
    url && BASE_URL + url,
    get,
    {
      revalidateIfStale: true,
      revalidateOnFocus: true,
      revalidateOnReconnect: true,
    },
  );

  // All responses are nested within a "data" attribute of the response.
  // The paginated responses have a "total" attribute which is a number.
  return { data: data?.data, total: data?.total, ...rest };
};
