import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import { URLS } from "config/urls";

import SetPassword from "pages/SetPassword";

const router = createBrowserRouter([
  {
    path: URLS.SET_PASSWORD,
    element: <SetPassword />,
  },
]);

const Routes = () => <RouterProvider router={router} />;

export default Routes;
